<template>
  <div class="pages" id="pagesContainer">
    <div class="page row js-page" id="mainPage" v-if="shopId != null">
      <!-- 顶部店铺信息 -->
      <div class="header row" :class="{'notin':orderAmount!=0}" id="header">
        <div class="header-left">
          <div data-slot-key='AVATAR'>
            <div class="avatar">
              <img v-if="storeInfo.shopLogo" :src="storeInfo.shopLogo" mode="scaleToFill" />
              <img v-else src="../../assets/images/store-logo.png" mode="scaleToFill" />
            </div>
          </div>
          <div class="avatar-badge-wrap">
            <div data-slot-key='AVATAR_BADGE'>
            </div>
          </div>
        </div>
        <div class="header-right">
          <div class="header-right-line-1">
            <div data-slot-key='MERCHANT_NAME'>
              <div class="merchant-name wx-fs-36">{{storeInfo.shopName}}</div>
            </div>
            <div data-slot-key='REMARK' @click="showDemo">
              <div class="add_remark_btn js-add-remark-btn wx-fs-26">备注</div>
            </div>
          </div>
          <div class="header-right-line-2">
            <div data-slot-key='STORE_NAME'>
              <div class="col">
              </div>
              <div class="store-name-c">
                <div class="store-name">
                  <span>
                    <span>门店</span>
                  </span>
                  <span class="wx-fs-26">{{storeInfo.shopAddr}}</span>
                </div>
              </div>
            </div>
            <div class="store-name-badge-c">
              <div data-slot-key='STORE_NAME_BADGE'>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 金额输入框 -->
      <div class="amountsDisC col js-main" id="amountsDisC" v-if="orderAmount==0">
        <div data-slot-key='AMOUNT_DISPLAY'>
          <div class="amountsDis" id="amountsDis">
            <div class="row input-amount">
              <div class="col label wx-fs-30">消费金额</div>
              <div class="value">
                <span class="currency">¥</span>
                <span class="amount js-amount-view">{{payMoney}} </span>
                <span class="cursor-c">
                  <div class="cursor js-cursor">
                  </div>
                </span>
              </div>
              <input placeholder="" name="amount" id="amount" type="hidden" readonly="readonly" />
            </div>
            <div class="js-discount-c" data-slot-key="DISPLAY_BOTTOM">
            </div>
          </div>
        </div>
        <div data-slot-key='CENTER'>
        </div>
      </div>
      <div data-slot-key='KEYBOARD_TOP' v-if="orderAmount==0">
        <div class="copyright-c" id="copyright">
          <div class="copyright wx-fs-26">
            <!-- <span class="copyright__brand"></span> -->
            <img src="../../assets/images/logo-icon.png" alt="" class="logo-icon" /> 优惠费率安全可信赖
          </div>
        </div>
      </div>
      <!-- 模拟键盘 -->
      <div data-slot-key='KEYBOARD' v-if="orderAmount==0">
        <div class="keyboard-c" id="keyboard">
          <div class="keyboard">
            <div class="keyboard__number-keys">
              <div class="row">
                <div class="keyboard__key" :class="{'keyboard__key--hover':activeKey=='1'}" data-key="1"
                  @touchstart.prevent="touchstart" @touchend.prevent="touchend">1</div>
                <div class="keyboard__key" :class="{'keyboard__key--hover':activeKey=='2'}" data-key="2"
                  @touchstart.prevent="touchstart" @touchend.prevent="touchend">2</div>
                <div class="keyboard__key" :class="{'keyboard__key--hover':activeKey=='3'}" data-key="3"
                  @touchstart.prevent="touchstart" @touchend.prevent="touchend">3</div>
              </div>
              <div class="row">
                <div class="keyboard__key" :class="{'keyboard__key--hover':activeKey=='4'}" data-key="4"
                  @touchstart.prevent="touchstart" @touchend.prevent="touchend">4</div>
                <div class="keyboard__key" :class="{'keyboard__key--hover':activeKey=='5'}" data-key="5"
                  @touchstart.prevent="touchstart" @touchend.prevent="touchend">5</div>
                <div class="keyboard__key" :class="{'keyboard__key--hover':activeKey=='6'}" data-key="6"
                  @touchstart.prevent="touchstart" @touchend.prevent="touchend">6</div>
              </div>
              <div class="row">
                <div class="keyboard__key" :class="{'keyboard__key--hover':activeKey=='7'}" data-key="7"
                  @touchstart.prevent="touchstart" @touchend.prevent="touchend">7</div>
                <div class="keyboard__key" :class="{'keyboard__key--hover':activeKey=='8'}" data-key="8"
                  @touchstart.prevent="touchstart" @touchend.prevent="touchend">8</div>
                <div class="keyboard__key" :class="{'keyboard__key--hover':activeKey=='9'}" data-key="9"
                  @touchstart.prevent="touchstart" @touchend.prevent="touchend">9</div>
              </div>
              <div class="row">
                <div class="keyboard__key keyboard__zero" :class="{'keyboard__key--hover':activeKey=='0'}" data-key="0"
                  @touchstart.prevent="touchstart" @touchend.prevent="touchend">0</div>
                <div class="keyboard__key keyboard__zero" :class="{'keyboard__key--hover':activeKey=='0'}" data-key="0"
                  @touchstart.prevent="touchstart" @touchend.prevent="touchend">
                </div>
                <div class="keyboard__key keyboard__point" :class="{'keyboard__key--hover':activeKey=='.'}" data-key="."
                  @touchstart.prevent="touchstart" @touchend.prevent="touchend">.</div>
              </div>
            </div>
            <div class="keyboard__function-keys">
              <div class="keyboard__key keyboard__del" :class="{'keyboard__key--hover':activeKey=='del'}" data-key="del"
                @touchstart.prevent="touchstart" @touchend.prevent="touchend">
                <i class="bi bi-delete">
                </i>
              </div>
              <div class="keyboard__key keyboard__enter"
                :class="{'keyboard__enter--disabled':(payMoney=='' || payMoney<=0 || payStatus==2)}" @click="submit"
                id="submit" data-key="enter" data-disabled="true" data-theme-bg-color="--theme-color"
                data-theme-bg-font-color="--theme-bg-font-color">
                <div class="normal normal-2 pay js-submit" id="fontSizeDetect" data-expect-font-size="42" v-if="payStatus==1">
                  <span>确认</span>
                  <span>支付</span>
                </div>
                <div class="normal normal-2 pay js-submitting" v-if="payStatus==2">
                  <span>正在</span>
                  <span>支付</span>
                </div>
                <div class="normal normal-3 limit js-limit" v-if="payStatus==3">
                  <span>单笔金额</span>
                  <span>不得超过</span>
                  <span class="line js-limit-num"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="iphonex-safe-place" v-if="orderAmount==0" style="background: rgb(255, 255, 255); height: env(safe-area-inset-bottom);height: constant(safe-area-inset-bottom); border-top: 1px solid rgb(211, 211, 214);"></div>
      <!-- 固定金额 -->
      <div class="fixed-amount col" v-if="orderAmount!=0">
        <div class="consume-title">消费金额</div>
        <div class="cus-value">
          <span class="cus-unit">¥</span>
          <span class="cus-amount">{{orderAmount}}</span>
        </div>
        <div class="pay-tips">固定金额不可修改</div>
        <van-button class="finish-bth" @click="submit">付款</van-button>
        <!-- <a href="javascript:;" class="finish-bth" @click="submit">付款</a> -->
      </div>
     
    </div>
    <div class="page row js-page" v-else>
      <div class="page404">
        <van-empty 
            description="门店参数不存在，请重新扫码付款吧~" 
        />
      </div>
    </div>
    <van-dialog title="付款备注" v-model="isShowDemo" show-cancel-button :beforeClose="beforeClose">
      <div>
        <van-cell-group inset>
          <van-field class="message-text" v-model="messageIn" rows="2" autosize type="textarea" maxlength="32"
            placeholder="请输入内容..." show-word-limit />
        </van-cell-group>
      </div>
    </van-dialog>
  </div>
</template>

<script>
  import { getOpenIdApi, getStoreInfoApi, paySubmitApi, getPayStatusApi } from "@/request/api";
  import { Toast } from 'vant'; 
  const ignoreKey = ['enter'];
  const DEFAULT_ERR_MSG = '呀，付款的人太多了。没挤进去。请重新扫码付款吧~';
  var toast1;
  export default {
    name: '',
    components: {},
    data () {
      return {
        loading: true,      // 遮罩层
        isClick: true,  //防止多次提交
        appId: 'wxad104e066a4e583a',  //公众号appId
        app_Id: '2021003127672362',  //支付宝appId
        merId: null,        //商户id
        shopId: null,       //门店id
        orderAmount: 0,  //订单金额  0表示不限制金额 这个参数永远不会为空
        storeInfo: {           //门店信息
          shopLogo: null,
          contactsName: null,
          contactsPhone: null,
          id: null,
          merId: null,
          remark: null,
          shopAddr: null,
          shopName: null,
        },
        sysOrderNo: null,   //系统订单号
        platform: '',     //平台  微信WX 支付宝ZFB 闪付扫码=YSF
        code: '',           //公众号code
        openId: '',         //公众号openId
        activeKey: '',      //当前点击的按键
        payMoney: '',       //支付金额
        payStatus: 1,       //支付状态 1等待支付 2支付中 3单笔金额不得超过
        isShowDemo: false,  //是否显示备注弹窗
        message: '',         //付款备注内容
        messageIn: '',       //付款备注内容
        jsApiPayInfo: null,
      };
    },
    // 引入支付宝jsapi
    mounted () {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = 'https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js';
      document.body.appendChild(s);
      window.onload =()=>{   //初始化产生一个全局变量 AlipayJSBridge
        this.readys()            
      }
    },
    created () {
      var that = this;
      that.merId = that.$route['query']['merId'];
      that.shopId = that.$route['query']['shopId'];
      that.orderAmount = that.$route['query']['orderAmount'];
      if(that.merId && that.shopId){
        that.IsWeixinOrAlipay();  //判断扫码APP客户端
      }
      // that.getPayStatus();
    },
    methods: {
      //判断扫码APP客户端，是否为微信、支付宝
      IsWeixinOrAlipay () {
        let that = this;
        toast1 = Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        var ua = window.navigator.userAgent.toLowerCase();
        console.log(ua)
        //判断是不是微信
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
          that.platform = "WX";
        }
        //判断是不是支付宝
        if (ua.match(/AlipayClient/i) == 'alipayclient') {
          that.platform = "ZFB";
        }
        //判断是不是云闪付
        if (ua.match(/CloudPay/i) == 'cloudpay') {
          that.platform = "YSF";
        }
        // localStorage.removeItem("openId");
        if (that.platform == "WX" || that.platform == "ZFB" || that.platform == "YSF") {
          that.getStoreInfo();  //获取门店信息
          //查询缓存中是否存在openid，存在则执行业务操作，不存在则获取code，请求接口获得openid操作
          that.openId = localStorage.getItem("openId");
          if (!that.openId || that.openId == "" || that.openId == null || that.openId == undefined || that.openId == "null") {
            //获取微信openid
            that.getBaseInfos();
          }
        } else {
          toast1.clear();
          //哪个都不是
          that.$router.replace({
            path: "/unableplat"
          })
        }
      },
      //获取门店信息
      getStoreInfo () {
        let that = this;
        if(that.shopId){
          const para = {
            'shopId': that.shopId
          }
          getStoreInfoApi(para).then(res => {
            toast1.clear();
            if (res.code == 200) {
              that.storeInfo = res.data;
            } else {
              Toast(res.msg || DEFAULT_ERR_MSG);
            }
          });
        }
      },
      //获取code
      getBaseInfos () {
        let that = this;
        let url = window.location.href;
        if (that.platform == "WX") {
          that.code = that.getUrlParam('code');
          if (that.code) {
            that.getOpenId();   //继续下一步获取openid
          } else {
            var url_code = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + that.appId + "&redirect_uri=" + that.urlencode(url) + "&response_type=code&scope=snsapi_base&state=h5#wechat_redirect";
            window.location.replace(url_code);//打开这个链接，你的url后面就会跟上code的参数 
          }
        } else if(that.platform == "ZFB"){
          that.code = that.getUrlParam('auth_code');
          if (that.code) {
            that.getOpenId();   //继续下一步获取openid
          } else {
            var url_code1 = "https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=" + that.app_Id + "&redirect_uri=" + that.urlencode(url) + "&scope=auth_base&state=h5";
            window.location.replace(url_code1);//打开这个链接，你的url后面就会跟上code的参数 
          }
        }else if(that.platform == "YSF"){  //云闪付
          that.code = that.getUrlParam('userAuthCode');
          if (that.code) {
            that.getOpenId();   //继续下一步获取openid
          } else {
            var url_code2 = "https://qr.95516.com/qrcGtwWeb-web/api/userAuth?version=1.0.0&redirectUrl=" + that.urlencode(url);
            window.location.replace(url_code2);//打开这个链接，你的url后面就会跟上code的参数 
          }
        }
      },
      //获取openid
      getOpenId () {
        let that = this;
        const para = {
          'code': that.code,
          'payType': that.platform
        }
        getOpenIdApi(para).then(res => {
          if (res.code == 200) {
            if(res.data && res.data != "" && res.data != "null" && res.data != null && res.data != undefined){
              that.openId = res.data;
              localStorage.setItem("openId", that.openId); //缓存openid
            }
          } else {
            Toast(res.msg || DEFAULT_ERR_MSG);
          }
        });
      },
      //编码函数
      urlencode (str) {
        str = (str + '').toString();
        return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').
          replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+');
      },
      //获取url链接中的参数，二维码中的参数
      getUrlParam (key) {
        var reg = new RegExp('(^|&)' + key + '=([^&]*)(&|$)', 'i');
        var r = window.location.search.substr(1).match(reg);
        if (r != null) {
            return decodeURIComponent(r[2]);
        }
        return null;

        // var params = {};
        // url = decodeURIComponent(url);//进行base64解码
        // if (url.split("?").length <= 1) {
        //   return '';
        // }
        // var arr = url.split("?")[1].split("&");
        // arr.forEach(function (item) {
        //   params[item.split("=")[0]] = item.split("=")[1];
        // });
        // return key ? params[key] : params;
      },
      //按键事件
      touchstart (evt) {
        let that = this;
        that.activeKey = evt.currentTarget.dataset.key;
        that.getInputValue(that.activeKey);
      },
      touchend () {
        this.activeKey = '';
      },
      //获取键盘值
      getInputValue (input) {
        let that = this;
        const originVal = that.payMoney;
        // 忽略的键值
        // 确认支付键
        if (ignoreKey.indexOf(input) !== -1) {
          return { amount: originVal };
        }

        let newValue = originVal;

        if (input === 'del') {
          // 删除键
          newValue = originVal ? originVal.substr(0, originVal.length - 1) : '';
        } else {
          // 普通键
          newValue = originVal + input;
        }

        // 不能有太多点
        if (newValue.split('.').length > 2) {
          newValue = originVal;
        }

        // 修正显示
        if (newValue === '.') {
          newValue = '0.';
        }

        if (newValue === '00') {
          newValue = '0';
        }

        // 不能输入太多 0
        if (
          newValue.length > 1 &&
          newValue[0] === '0' &&
          newValue[1] !== '0' &&
          newValue[1] !== '.'
        ) {
          [newValue] = newValue;
        }

        // 不能超过两位小数
        if (
          newValue.toString().indexOf('.') !== -1 &&
          newValue.toString().split('.')[1].length > 2
        ) {
          newValue = originVal;
        }
        // 最大到万级
        if (newValue.toString().split('.')[0].length > 5) {
          newValue = originVal;
        }
        that.payMoney = newValue;
      },
      //备注弹窗
      showDemo () {
        this.isShowDemo = true;
      },
      //关闭事件
      beforeClose: function (action, done) { // 点击事件 - 备注按钮提示框
        let that = this;
        if (action === 'confirm') { // 确认
          that.message = that.messageIn;
          done(); // 关闭提示框
        } else if (action === 'cancel') { // 取消
          that.messageIn = that.message;
          done(); // 关闭提示框
        }
      },
      //确认支付
      submit () {
        let that = this;
        if(that.isClick){
          that.isClick = false;
          if((that.payMoney>0 && that.payStatus==1) || that.orderAmount>0){
            that.payStatus=2;
            var appid = that.appId;
            var payType = null;
            if(that.platform == 'WX'){
              appid = that.appId;
              payType = 7;
            }else if(that.platform == 'ZFB'){
              appid = that.app_Id;
              payType = 8;
            }else if(that.platform == 'YSF'){
              appid = that.appId;
              payType = 9;
            }
            const para = {
              'appId': appid,
              'merId': that.merId,
              'orderAmount': that.orderAmount == 0 ? Number(that.payMoney) : Number(that.orderAmount),
              'payType': payType,
              'remark': that.message,
              'shopId': that.shopId,
              'userId': that.openId,
              'uqrCode':window.location.href
            }
            paySubmitApi(para).then(res => {
              if (res.code == 200) {
                that.sysOrderNo = res.data.sysOrderNo;

                if (that.platform == 'WX') {
                  that.jsApiPayInfo = JSON.parse(res.data.jsApiPayInfo);
                  //微信支付
                  that.goWxpay(that.jsApiPayInfo);
                } else if (that.platform == 'ZFB') {
                  that.jsApiPayInfo = JSON.parse(res.data.jsApiPayInfo);
                  //支付宝支付
                  if (window.AlipayJSBridge) {
                    that.goAlipay(that.jsApiPayInfo);
                  } else {
                    document.addEventListener('AlipayJSBridgeReady',  that.goAlipay(that.jsApiPayInfo), false);
                  }
                }else if(that.platform == 'YSF'){ //云闪付付款
                  that.jsApiPayInfo = res.data.jsApiPayInfo;
                  window.location.href = that.jsApiPayInfo;
                  // window.location.replace(that.jsApiPayInfo);
                }
                that.isClick = true;
              } else {
                Toast(res.msg || DEFAULT_ERR_MSG);
                that.isClick = true;
                that.payStatus=1;
              }
            });
          }
        }
      },
      //唤起微信支付
      goWxpay (jsApiPayInfo) {
        let that = this;
        window.WeixinJSBridge.invoke(
          'getBrandWCPayRequest',
          jsApiPayInfo,
          function (res) {
            console.log(res);
            // Toast('支付回调参数：'+JSON.stringify(res));
            //成功后返回ok，但并不保证它绝对可靠，继续请求接口查询是否真的支付成功
            if (res.err_msg == "get_brand_wcpay_request:ok") {   
              that.getPayStatus();              
            }else if(res.err_msg == "get_brand_wcpay_request:cancel"){ 
              that.isClick = true;
              that.payStatus=1;
            }else if(res.err_msg == "get_brand_wcpay_request:fail"){ 
              that.getPayStatus(); 
            }
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          }
        );
      },
      //唤起支付宝支付
      goAlipay (jsApiPayInfo) {
        let that = this;
        window.AlipayJSBridge.call("tradePay", jsApiPayInfo, function (res) {
          console.log(res)
          // Toast(res.resultCode);
          if(res.resultCode!=9000){
            //支付失败              
            //   alert(data.resultCode+"："+data.memo);
            that.isClick = true;
            that.payStatus=1;
          }else{
            that.getPayStatus();  
            //支付成功
          }
        });
      },
      //支付宝支付
      readys(callback) {
        console.log(window)
        if (window.AlipayJSBridge) {
            callback && callback();
        } else {
            document.addEventListener('AlipayJSBridgeReady', callback, false);
        }
      },
      //查询支付是否成功
      getPayStatus () {
        let that = this;
        toast1 = Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        const para = {
          'sysOrderNo': that.sysOrderNo
        }
        getPayStatusApi(para).then(res => {
          toast1.clear();
          if (res.code == 200) {
            // Toast(res.data.payStatus);

            //支付状态（1=处理中 2=下单成功 3=下单失败 4=支付成功 5=支付失败 6=已退款）
            if (res.data.payStatus == 4) {
              that.$router.replace({
                path: "/paystatus",
                query: { 
                  status: 1,
                  plat: that.platform,
                  id: that.sysOrderNo,  //订单号
                  name: that.storeInfo.shopName,  //门店名称
                  amount: that.orderAmount == 0 ? Number(that.payMoney) : Number(that.orderAmount),  //订单金额
                  payinfo: JSON.stringify(that.jsApiPayInfo)  //发起原生支付参数
                }
              })
            } else if (res.data.payStatus == 5) {
              that.$router.replace({
                path: "/paystatus",
                query: { 
                  status: 0,
                  plat: that.platform,
                  id: that.sysOrderNo,  //订单号
                  name: that.storeInfo.shopName,  //门店名称
                  amount: that.orderAmount == 0 ? Number(that.payMoney) : Number(that.orderAmount),  //订单金额
                  payinfo: JSON.stringify(that.jsApiPayInfo)  //发起原生支付参数
                }
              })
            }else if(res.data.payStatus == 1){
              that.$router.replace({
                path: "/paystatus",
                query: { 
                  status: 2,
                  plat: that.platform,
                  id: that.sysOrderNo,  //订单号
                  name: that.storeInfo.shopName,  //门店名称
                  amount: that.orderAmount == 0 ? Number(that.payMoney) : Number(that.orderAmount),  //订单金额
                  payinfo: JSON.stringify(that.jsApiPayInfo)  //发起原生支付参数
                }
              })
            }else{
              Toast(res.msg || DEFAULT_ERR_MSG);
            }
          } else {
            Toast(res.msg || DEFAULT_ERR_MSG);
          }
        });
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../assets/style/pay.scss"; 
</style>